@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.footer-container {
  background-color: rgba(242, 250, 249, 1);
  // margin-top: $spacing-lg;
  margin-top: -4px;
  @include breakpoint(md) {
    margin-top: $spacing-md;
  }
  .footer-content {
    border-bottom: 2px solid rgba(134, 135, 135, 0.3);
    width: 85%;
    margin-inline: auto;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 50px 0px;
    @include breakpoint(md) {
      flex-direction: column;
      padding: 20px 0 30px 0;
      align-items: center;
      width: 90%;
      gap: 25px;
    }
    .logo-description {
      width: 41%;
      @include breakpoint(md) {
        width: 95%;
        text-align: center;
      }
      .logo {
        @include breakpoint(md) {
          display: flex;
          justify-content: center;
        }
        svg {
          display: block;
          cursor: pointer;
        }
      }
      .description {
        text-align: left;
        margin: 10px 0 0 0;
        color: $tertiaryColor;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.14px;
        @include breakpoint(md) {
          text-align: center;
        }
      }
      .description2 {
        margin-top: 30px;
        font-size: 12px;
        font-weight: 700;

        @include breakpoint(md) {
          margin-top: 20px;
        }
      }
    }
    .listed-social {
      display: flex;
      justify-content: space-between;
      width: 50%;
      @include breakpoint(md) {
        width: 100%;
        margin-inline: auto;
        flex-direction: column;
        align-items: center;
        gap: 18px;
      }
      .listed-items,
      .social-icons {
        h3 {
          font-size: 16px;
          font-weight: 700;
          color: $primaryColor;
          margin: 0;
          text-align: start;
          line-height: 26.58px;
          @include breakpoint(md) {
            text-align: center;
          }
        }
      }
      .listed-items {
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            text-align: start;
            color: $primaryColor;
            font-size: 14px;
            font-weight: 400;
            line-height: 30.37px;
            cursor: pointer;

            @include breakpoint(md) {
              text-align: center;
            }
          }
        }
      }
      .icons {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 5px;
        @include breakpoint(md) {
          gap: 10px;
        }
        .icon {
          padding: 10px;
          background-color: $light;
          border-radius: 100%;
          box-shadow: $shadow;
          cursor: pointer;
          &:hover {
            background-color: rgb(244, 252, 255);
          }
          svg {
            display: block;
            width: 18px;
            height: 18px;
          }
          @include breakpoint(md) {
            padding: 8px;
          }
        }
      }
    }
  }
  .rights {
    margin: 0;
    padding: 15px 0 25px 0;
    text-align: center;
    color: $tertiaryColor;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    width: 82%;
    margin-left: 9%;
    @include breakpoint(md) {
      padding: 10px 22px;
      font-size: 12px;
      width: 100%;
      margin-left: 0%;
    }
    .bottom-text {
      display: flex;
      gap: 25px;
      cursor: pointer;
      @include breakpoint(md) {
        gap: 12px;
      }
    }
  }
  .social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
