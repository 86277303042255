// @import "../Styles/mixins.scss";

@mixin heading {
  font-weight: 700;
  font-size: 30px;
  color: $primaryColor;
}
@mixin subHeading {
  font-weight: 700;
  font-size: 14px;
}
@mixin description {
  font-weight: 400;
  font-size: 12px;
  color: $primaryColor;
  line-height: 16.5px;
  text-align: justify;
}

@mixin breakpoint($point) {
  @if $point == md {
    // 768px
    @media (max-width: 48rem) {
      @content;
    }
  }
}





@mixin flex($direction: row, $justify: "", $align: stretch, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  @if $justify != "" {
    justify-content: $justify;
  }
  align-items: $align;
  gap: $gap;
}
