@import "../../../Styles/variables.scss";
@import "../../../Styles/mixins.scss";

.button {
  padding: 12px 8px;
  border-radius: $radius;
  border: 1px solid $secondaryColor;
  background-color: $secondaryColor;
  color: $primaryColor;
  font-size: 14px;
  width: 100%;

  font-family: inherit;
  font-weight: 800;
  cursor: pointer;
  width: 163px;
  &:hover {
    background-color: $light;
    border-color: $primaryColor;
  }
}
