.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.no-blog {
  text-align: center;
  padding: 40px;
}

.blog-detail {
  padding: 20px;
  margin-top: 120px;
  margin-bottom: 50px;

  .main-container {
    height: 300px;
    max-height: 400px;
    display: flex;
    justify-content: center;
    margin-block: 15px;

    img {
      object-fit: contain;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
