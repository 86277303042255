.all-blogs-container {
  padding: 20px;

  .all-blogs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .card-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
  }

  .card-tags {
    margin-bottom: 12px;
  }

  .card-footer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }
}
