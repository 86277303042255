#accordionContainer {
  width: 85%;
  margin-inline: auto;
  margin-bottom: 85px;
  margin-top: 75px;
}

.accordionHeading {
  font-size: 28px;
  font-weight: 700;
  color: #25434d;
  text-align: center;
}

.ant-collapse-header {
  font-size: 14px;
  font-family: "Gabarito", sans-serif;
  font-weight: 500;
}

p {
  font-family: "Gabarito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #565656;
}

.ant-collapse-content-box {
  background-color: rgb(104, 138, 172, 0.1) !important;
}
