@import "../../Styles/variables.scss";
@import "../../Styles/mixins.scss";
.diseasesContainer {
  margin-bottom: 80px;
  .diseases {
    background-color: $primaryColor;
    border-radius: $radius;
    padding: 50px;
    margin-top: $spacing-lg;
    width: 85%;
    margin-inline: auto;
    @include breakpoint(md) {
      width: 100%;
      border-radius: 0;
      margin-top: $spacing-md;
      padding: 30px 16px 25px 16px;
    }
    h1 {
      font-size: 28px;
      font-weight: 500;
      color: $light;
      line-height: 37.66px;
      margin: 0;
      width: 42%;
      margin-inline: auto;
      text-align: center;
      @include breakpoint(md) {
        font-size: 18px;
        line-height: 24.21px;
        width: 85%;
      }
    }
    .diseaseCards {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      margin-top: 30px;
      @include breakpoint(md) {
        margin-top: 20px;
        gap: 10px;
        flex-wrap: nowrap;
        flex-direction: column;
      }
      .diseaseArray {
        display: flex;
        align-items: center;
        justify-content: start;
        background-color: $light;
        border-radius: $radius;
        width: 48%;
        gap: 10px;

        gap: 100px;
        padding: 5px;
        padding-left: 25px !important;
        cursor: pointer;
        &:hover {
          background-color: rgba(242, 250, 249, 1);
        }
        @include breakpoint(md) {
          width: 100%;
          gap: 80px;
          padding-left: 20px;
        }
        svg {
          display: block;
          @include breakpoint(md) {
            width: 114px;
            height: auto;
          }
        }
        .diseaseImg {
          width: 110px !important;
          height: 110px !important;
          object-fit: contain !important;
          object-position: center;
        }

        h2 {
          font-size: 28px;
          font-weight: 500;
          color: $primaryColor;
          @include breakpoint(md) {
            font-size: 18px;
          }
        }
      }
    }
    .more-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      margin-top: 50px;
      @include breakpoint(md) {
        margin-top: 25px;
      }
      svg {
        width: 20px;
        height: 22px;
        display: block;
        path {
          fill: $secondaryColor;
        }
      }
      span {
        font-size: 18px;
        font-weight: 600;
        color: $secondaryColor;
      }
    }
  }
}
