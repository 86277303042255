.ql-editor {
  min-height: 200px;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}
