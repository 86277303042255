@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primaryColor;
  padding: 0px 40px;
  height: 80px;
  position: fixed;
  z-index: 100;
  transition: top 0.3s ease;
  top: 0; /* Initially at the top */
  width: 100%;
  max-width: 1440px;

  &.adjusted {
    top: 34px; /* Adjusted position when top-nav-bar is visible */
  }

  @include breakpoint(md) {
    padding: 0px 20px;
    align-items: start;
    // flex-direction: column;
    height: 70px;
    align-items: center;
    gap: 20px;
  }
  .logo-menu {
    @include breakpoint(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .logo {
      cursor: pointer;
      svg {
        display: block;
      }
    }
    .menu,
    .change {
      cursor: pointer;
      display: none;
      @include breakpoint(md) {
        display: inline-block;
      }
      .bar1,
      .bar2,
      .bar3 {
        width: 35px;
        height: 5px;
        background-color: $light;
        margin: 6px 0;
        transition: 0.4s;
      }
    }
    .change {
      .bar1 {
        transform: translate(0, 11px) rotate(225deg);
      }

      .bar2 {
        opacity: 0;
      }

      .bar3 {
        transform: translate(0, -11px) rotate(-225deg);
      }
    }
  }
  .list-button {
    display: flex;
    align-items: center;
    gap: 25px;
    // width: 42%;
    justify-content: end;
    @include breakpoint(md) {
      transform: translateY(-1000px);
      position: absolute;
      flex-direction: column;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 40px;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        font-size: 14px;
        font-weight: 400;
        color: $light;
        cursor: pointer;
        a {
          color: $light;
          cursor: pointer;
        }
        span {
          font-weight: 700;
          color: $secondaryColor;
        }
      }
    }
    .button {
      // width: 28%;
    }
    .buttonFlex {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 30%;
      padding: 9px 5px !important;
      .arrowNextBtn {
        font-size: 23px;
        background: aliceblue;
        border-radius: 10px;
      }
    }
  }
  .open {
    display: block;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 72px;
    width: 100%;
    background-color: rgba(242, 250, 249, 1);
    padding: $spacing-md;
    box-shadow: $shadow;
    // border-radius: $radius;
    transform: translateY(-0px);
    transition: transform 300ms ease-in-out;
    // transition-duration: 1s;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing-md;
      padding: 0;
      li {
        list-style: none;
        color: $primaryColor;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        span {
          font-weight: 700;
          color: $tertiaryColor;
        }
      }
    }
    .button {
      width: 70%;
    }
  }
}

/* Simple Top Navigation Bar */
.top-nav-bar {
  width: 100%;
  max-width: 1440px;
  background-color: #2ba89a;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 10px 0;
  height: 34px;
  position: fixed;
  top: -50px;
  z-index: 2000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s ease;
  transition: opacity 2s ease-in-out;
  &.visible {
    animation: slideDown 3s ease forwards;
  }
}

@keyframes slideDown {
  from {
    top: -0px;
  }
  to {
    top: 0;
  }
}
.top-nav-bar .small-div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fad70e;
  margin-right: 8px;
}

.centered-text {
  font-size: 12px;
  // font-weight: bold;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}
