.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.no-blog {
  text-align: center;
  padding: 40px;
}

.blog-detail-container {
  padding: 20px;
  margin-block: 60px;
  height: 300px;
  max-height: 400px;
  margin-block: 15px;

  img {
    object-fit: contain;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
