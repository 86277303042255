@import "../../../Styles/mixins.scss";
@import "../../../Styles/variables.scss";

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $lightGray;
  padding: 20px;
}

.login-form-wrapper {
  background-color: $light;
  border-radius: $radius;
  box-shadow: $shadow;
  width: 100%;
  max-width: 450px;
  padding: 30px;

  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 200px;
      height: 70px;
    }
  }

  h1 {
    text-align: center;
    @include heading;
    margin-block: 20px;
  }

  .login-subtitle {
    text-align: center;
    margin: 0 0 25px;
    color: $tertiaryColor;
    font-size: 16px;
  }
}

.login-form {
  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $primaryColor;
    }

    input {
      width: 100%;
      padding: 12px 15px;
      border: 1px solid $mediumGray;
      border-radius: $borderRadius;
      font-size: 15px;
      transition: border-color 0.2s;
      outline: none;

      &:focus {
        border-color: $secondaryColor;
      }

      &.error {
        border-color: $errorColor;
      }

      &::placeholder {
        color: $tertiaryColor;
      }

      &:disabled {
        background-color: $lightGray;
        cursor: not-allowed;
      }
    }

    .error-text {
      display: block;
      color: $errorColor;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  .error-message {
    background-color: rgba($errorColor, 0.1);
    color: $errorColor;
    padding: 12px;
    border-radius: $borderRadius;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .login-button {
    width: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

// Responsive adjustments
@media (max-width: 480px) {
  .login-form-wrapper {
    padding: 20px;

    h1 {
      font-size: 24px;
    }

    .login-subtitle {
      font-size: 14px;
    }
  }

  .login-form {
    .form-group {
      margin-bottom: 15px;

      input {
        padding: 10px 12px;
      }
    }

    .login-button {
      padding: 12px;
    }
  }
}
