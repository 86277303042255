@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.main {
  padding: 120px 80px 40px;
  @include breakpoint(md) {
    padding: 125px 15px;
  }
}
.termHeading {
  font-size: 34px;
  color: $primaryColor;
  text-align: center;
  margin-block: 50px;

  @include breakpoint(md) {
    font-size: 18px;
    text-align: center;
    margin-block: 15px;
  }
}

.termSection {
  margin-block: 30px;

  h2 {
    font-size: 28px;
    color: $primaryColor;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: 15px;
      text-align: center;
    }
  }

  p {
    color: #868787;
    font-size: 15px;
    text-align: justify;
  }

  ul {
    list-style: disc;
  }

  li {
    color: #868787;
    margin-block: 20px;
  }

  &:last-of-type {
    margin-bottom: 150px;
  }
}
