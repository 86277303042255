@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800&display=swap");
// Fonts
$Gabarito: "Gabarito", sans-serif;
// COLORS
$primaryColor: #25434d;
$secondaryColor: #a7e8e0;
$tertiaryColor: #868787;
$light: #fff;
$borderRadius: 7px;
$lightGray: #f5f5f5;
$mediumGray: #e0e0e0;
$errorColor: #ef476f;

// spacing
$spacing-md: 40px;
$spacing-lg: 80px;
// border radius
$radius: 8px;
$buttonRadius: 20px;
// Box Shadow
$shadow: 2px 2px 1rem 2px rgba(121, 119, 119, 0.13);
// header height
$headerHeight: 82px;
