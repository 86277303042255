@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.landing-container {
  width: 100%;
  margin-inline: auto;
  .main {
    background-color: rgba(242, 250, 249, 1);
    display: flex;
    align-items: center;
    padding: 135px 0 15px 120px;
    @include breakpoint(md) {
      flex-direction: column;
      padding: 0;
    }
    h5 {
      display: none;
      @include breakpoint(md) {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: $primaryColor;
        span {
          font-weight: 800;
        }
      }
    }
    .heading {
      text-align: start;
      width: 100%;
      @include breakpoint(md) {
        text-align: center;
        margin-top: 125px;
      }

      h1 {
        margin: 0;
        font-size: 46px;
        font-weight: 500;
        // line-height: 61.87px;
        width: 500px;
        color: $primaryColor;
        position: relative;
        display: flex;
        align-items: last baseline;
        justify-content: center;
        @include breakpoint(md) {
          font-size: 24px;
          line-height: 32.28px;
          width: 290px;
          margin-inline: auto;
          margin-top: 20px;
        }
        // svg {
        //   display: block;
        //   margin-left: -330px;
        //   margin-bottom: 1px;
        //   @include breakpoint(md) {
        //     margin-left: -120px;
        //   }
        // }
      }
      p {
        font-size: 17px;
        font-weight: 400;
        line-height: 19.14px;
        color: $tertiaryColor;
        width: 95%;
        margin-top: 15px;
        @include breakpoint(md) {
          width: 86%;
          margin-inline: auto;
          margin-top: 12px;
          font-size: 14px;
        }
      }
      .button {
        margin-top: 30px;
        width: 35%;
        padding: 12px;

        @include breakpoint(md) {
          width: 50%;
          margin-top: 32px;
        }
      }
    }
    img {
      display: block;
      // height: auto;
      width: 100%;
      max-width: 59%;
      height: 150%;
      object-fit: cover;
      object-position: center;
      padding-top: 0 !important;

      @include breakpoint(md) {
        margin-top: 52px;
        width: 100%;
        height: auto;
        max-width: 100vw;
      }
    }
  }
  .button-heading {
    width: 90%;
    margin-inline: auto;
    margin-top: -90px;
    z-index: 1;
    @include breakpoint(md) {
      width: 100%;
      margin: 0;
    }
    h6 {
      font-size: 18px;
      font-weight: 600;
      color: $primaryColor;
      text-align: start;
      @include breakpoint(md) {
        text-align: center;
        background-color: rgba(242, 250, 249, 1);
        width: 100%;
        padding: 20px 0 10px 0;
      }
    }
    .mega-button {
      background-color: $light;
      padding: 30px 45px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: $shadow;
      border-radius: $radius;
      @include breakpoint(md) {
        flex-direction: column;
        align-items: start;
        border-radius: 0;
        padding: 40px 30px;
        margin: 0;
        gap: 30px;
      }
      .button-array {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        @include breakpoint(md) {
          justify-content: start;
        }
        svg {
          display: block;
        }
        .icon {
          background-color: rgba(242, 250, 249, 1);
          padding: 13px;
          border-radius: 100%;
          width: 46px;
          height: 46px;
          svg {
            display: block;
            width: 21px;
            height: 25x;
          }
        }
        h2 {
          font-size: 18px;
          font-weight: 600;
          line-height: 21.6px;
          width: 40%;
          text-align: start;
        }
        .arrow-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
          span {
            font-size: 12px;
            font-weight: 400;
            color: $tertiaryColor;
          }
        }
      }
    }
  }
  .video-session {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-lg;
    gap: 70px;
    width: 70%;
    margin-inline: auto;
    @include breakpoint(md) {
      width: 100%;
      flex-direction: column;
      margin-top: $spacing-md;
      gap: 15px;
    }
    .doctor {
      svg {
        display: block;
        background-color: #d9d9d9;
        border-radius: $radius;
        @include breakpoint(md) {
          width: 200px;
          height: auto;
        }
      }
    }
    .description {
      width: 58%;
      text-align: start;
      @include breakpoint(md) {
        width: 88%;
        text-align: center;
      }
      h2 {
        font-size: 34px;
        font-weight: 500;
        line-height: 37.66px;
        color: $primaryColor;
        // width: 93%;
        margin: 0;
        @include breakpoint(md) {
          width: 100%;
          font-size: 18px;
          line-height: 21.6px;
        }
      }
      p {
        font-size: 17px;
        font-weight: 400;
        text-align: justify;
        line-height: 20.14px;
        color: $tertiaryColor;
        width: 100%;
        margin: 0;
        margin-top: 10px;
        @include breakpoint(md) {
          margin-top: 6px;
          text-align: justify;
          font-size: 13px;
        }
      }
      .session-button {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba(208, 221, 225, 1);
        border-radius: $radius;
        background-color: rgba(242, 250, 249, 1);
        padding: 25px 35px 25px 25px;
        width: 70%;
        cursor: pointer;
        @include breakpoint(md) {
          width: 100%;
          margin-top: 20px;
        }
        svg {
          display: block;
        }
        .button-icon {
          display: flex;
          align-items: center;
          gap: 12px;
          h3 {
            font-size: 16px;
            font-weight: 600;
            width: 80%;
            line-height: 21.52px;
            color: $primaryColor;
            margin: 0;
            @include breakpoint(md) {
              text-align: start;
            }
          }
        }
      }
    }
  }
  .diseases {
    background-color: $primaryColor;
    border-radius: $radius;
    padding: 40px;
    margin-top: $spacing-lg;
    width: 85%;
    margin-inline: auto;
    @include breakpoint(md) {
      width: 100%;
      border-radius: 0;
      margin-top: $spacing-md;
      padding: 30px 16px 25px 16px;
    }
    h1 {
      font-size: 25px;
      font-weight: 500;
      color: $light;
      line-height: 37.66px;
      margin: 0;
      width: 42%;
      margin-inline: auto;
      text-align: center;
      @include breakpoint(md) {
        font-size: 18px;
        line-height: 24.21px;
        width: 85%;
      }
    }
    .disease-cards {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      margin-top: 30px;
      @include breakpoint(md) {
        margin-top: 20px;
        gap: 10px;
        flex-wrap: nowrap;
        flex-direction: column;
      }
      .disease-flex {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
      }
      .disease-array {
        display: flex;
        width: 31%; /* 3 cards per row */
        align-items: center;
        justify-content: space-between;
        background-color: $light;
        border-radius: $radius;
        // width: 48%;
        // gap: 10px;
        // gap: 44px;
        padding: 5px;
        padding-left: 5px !important;
        padding-right: 20px;
        cursor: pointer;

        &:hover {
          background-color: rgba(242, 250, 249, 1);
        }
        @include breakpoint(md) {
          width: 100%;
          // gap: 80px;
          padding-left: 20px;
        }
        svg {
          display: block;
          @include breakpoint(md) {
            width: 114px;
            height: auto;
          }
        }
        .diseaseImg {
          width: 110px !important;
          height: 110px !important;
          object-fit: contain !important;
          // object-position: flex-end;
          @include breakpoint(md) {
            width: 105px !important;
            height: 105px !important;
          }
        }

        h2 {
          font-size: 19px;
          font-weight: 500;
          color: $primaryColor;
          @include breakpoint(md) {
            font-size: 18px;
          }
        }
      }
    }
    .more-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      margin-top: 50px;
      @include breakpoint(md) {
        margin-top: 25px;
      }
      svg {
        width: 20px;
        height: 22px;
        display: block;
        path {
          fill: $secondaryColor;
        }
      }
      span {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 600;
        color: $secondaryColor;
      }
    }
  }
  #consult-steps {
    text-align: start;
    margin-top: $spacing-lg;
    width: 85%;
    margin-inline: auto;
    @include breakpoint(md) {
      margin-top: $spacing-md;
    }
    h1 {
      font-size: 34px;
      // font-weight: 500;
      color: $primaryColor;
      text-align: center;
      @include breakpoint(md) {
        font-size: 18px;
        text-align: center;
      }
    }
    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 16.14px;
      color: $tertiaryColor;
      // width: 40%;
      margin-top: 10px;
      text-align: center;
      @include breakpoint(md) {
        margin-top: 5px;
        width: 100%;
        text-align: center;
      }
    }
    .steps-cards {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 30px;
      @include breakpoint(md) {
        margin-top: 20px;
        flex-direction: column;
        gap: 15px;
      }
      .steps-array {
        border: 1px solid rgba(208, 221, 225, 1);
        border-radius: $radius;
        padding: 25px;
        display: flex;
        align-items: start;
        // justify-content: center;
        gap: 20px;
        width: 33.3%;
        height: 130px;
        // cursor: pointer;
        &:hover {
          // background-color: rgba(242, 250, 249, 1);
        }
        @include breakpoint(md) {
          width: 100%;
          padding: 20px;
          height: auto;
        }
        span {
          font-size: 24px;
          font-weight: 500;
          color: $tertiaryColor;
          @include breakpoint(md) {
            font-size: 16px;
          }
        }
        .headiang-des {
          h2,
          p {
            margin: 0;
          }
          h2 {
            font-size: 24px;
            font-weight: 700;
            color: $primaryColor;
            @include breakpoint(md) {
              font-size: 16px;
            }
          }
          p {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: $tertiaryColor;
            line-height: 16.34px;
          }
        }
      }
    }
  }
  #consult-steps2 {
    text-align: start;
    margin-top: $spacing-lg;
    width: 85%;
    margin-inline: auto;
    @include breakpoint(md) {
      margin-top: $spacing-md;
    }
    h1 {
      font-size: 34px;
      // font-weight: 500;
      color: $primaryColor;
      text-align: center;
      @include breakpoint(md) {
        font-size: 18px;
        text-align: center;
      }
    }
    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 16.14px;
      color: $tertiaryColor;
      // width: 40%;
      margin-top: 10px;
      text-align: center;
      @include breakpoint(md) {
        margin-top: 5px;
        width: 100%;
        text-align: center;
      }
    }
    .steps-cards2 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-top: 40px;
      @include breakpoint(md) {
        margin-top: 20px;
        flex-direction: column;
        gap: 15px;
      }
      .steps-array2 {
        border: 1px solid rgba(208, 221, 225, 1);
        border-radius: $radius;
        padding: 15px 12px;
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 15px;
        width: 33.3%;
        height: 90px;
        // cursor: pointer;
        &:hover {
          // background-color: rgba(242, 250, 249, 1);
        }
        @include breakpoint(md) {
          width: 100%;
          height: auto;
        }
        span {
          font-size: 24px;
          font-weight: 500;
          color: $tertiaryColor;
          @include breakpoint(md) {
            font-size: 16px;
          }
        }
        .headiang-des2 {
          h2,
          p {
            margin: 0;
          }
          h2 {
            font-size: 24px;
            font-weight: 700;
            color: $primaryColor;
            @include breakpoint(md) {
              font-size: 16px;
            }
          }
          p {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: $tertiaryColor;
            line-height: 16.34px;
          }
        }
      }
    }
  }
  .bottom-button {
    margin-top: $spacing-lg;
    margin-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 70px;
    border-radius: 93px;
    box-shadow: $shadow;
    width: 60%;
    margin-inline: auto;
    @include breakpoint(md) {
      width: 90%;
      margin-top: $spacing-md;
      border-radius: $radius;
      flex-direction: column;
      padding: 20px 0;
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      color: $primaryColor;
      @include breakpoint(md) {
        font-size: 18px;
        text-align: center;
      }
    }
    @include breakpoint(md) {
      a {
        width: 40%;
        .button {
          width: 100%;
          text-align: center;
          margin-top: 15px;
        }
      }
    }
  }
}
