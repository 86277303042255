.testmonialContainer {
  margin: 70px 0;
}
.carousel-root {
  width: 75% !important;
  margin: auto !important;
  margin-top: 2% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

/* .carousel .slide img {
    width: 125px !important;
    border-radius: 10%;
  } */

.myCarousel {
  background: #fff;
  /* margin-left: auto;
  margin-right: auto; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(208, 221, 225);
  transition: all 0.2s ease-in;
  height: 186px;
  border-radius: 10px;
}

/* .myCarousel:hover {
  background: #fff;
} */

/* .carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
} */

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #868787;
  font-size: 15px;
  font-family: sans-serif;
  /* max-height: 67px; */
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.myCarousel .rating {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  color: #25434d;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}
@media (max-width: 48rem) {
  .carousel .control-dots {
    bottom: -40px;
  }
  .carousel.carousel-slider {
    overflow: visible;
  }
}

.carousel.carousel-slider .control-arrow {
  background: #a7e8e0 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
  border-radius: 6px;
}

.App {
  text-align: center;
}
.testimonialContainer {
  margin: 80px 0;
}
.testimonialHeading {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #25434d;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #25434d !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #25434d !important;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
@media (max-width: 48rem) {
  .buttonContainer {
    display: none;
  }
}

.carouselArrow {
  background-color: transparent;
  border: 1px solid #331d2c;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  margin: 0 10px;
  outline: none;
}

.prevArrowIcon {
  rotate: 180deg;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    /* background: #ffff; */
    /* margin-top: -9%; */
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid rgb(208, 221, 225);
    height: 269px;
    margin-top: 20px;
  }
  .myCarousel :hover {
    background-color: rgba(242, 250, 249, 1);
  }
}
