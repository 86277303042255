@import "../../Styles/variables.scss";

#contactForm {
  // background-color: #f8fbff;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  margin-top: 70px;
  h1 {
    color: #25434d;
    font-size: 35px;
    font-weight: 800;
    font-family: $Gabarito;
    padding-bottom: 2rem;
  }
  .input_fields {
    width: 60%;
    border-radius: 20px;
    padding-inline: 100px;
    padding-block: 60px;
    box-shadow: 0px 0.31px 3.15px 0px #00000002, 0px 1.39px 6.52px 0px #00000003,
      0px 3.4px 13px 0px #00000004, 0px 6.55px 25.48px 0px #00000005,
      0px 11.02px 46.85px 0px #00000006, 0px 17px 80px 0px #00000008;

    @media screen and (max-width: 540px) {
      width: 100% !important;
      padding-inline: 20px;
      padding-block: 40px;
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
      position: relative;
      input {
        padding: 15px 17px;
        outline: none;
        background: transparent;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        font-size: 13px;
        color: gray;
        &::placeholder {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #bdbdbd;
        }
        &:focus {
          border-color: #a7e8e0;
        }
      }
      .custom-select {
        display: flex;
        flex-direction: column;
        position: relative;
        select {
          padding: 10px 17px;
          outline: none;
          background: transparent;
          border: 1px solid #bdbdbd;
          border-radius: 10px;
          font-size: 13px;
          color: gray;
          cursor: pointer;
          -webkit-appearance: none !important;
          &::placeholder {
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 500;
            color: #bdbdbd;
          }
        }
        .arrow {
          position: absolute;
          right: 11px;
          top: 10px;
          font-size: 24px;
          color: #bdbdbd;
          cursor: pointer;
        }
      }
      textarea {
        padding: 13px 17px;
        outline: none;
        background: transparent;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        font-size: 13px;
        color: gray;
        &::placeholder {
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-weight: 500;
          color: #bdbdbd;
        }
        &:focus {
          border-color: #a7e8e0;
        }
      }
      .error_name {
        margin-top: -10px;
        font-size: 11px;
        margin-left: 5px;
        color: red;
      }
      .error_email {
        margin-top: -10px;
        font-size: 11px;
        margin-left: 5px;
        color: red;
      }
      .error_message {
        margin-top: -10px;
        font-size: 11px;
        margin-left: 5px;
        color: red;
      }
      .error_select {
        font-size: 11px;
        margin-left: 15px;
        color: red;
      }
    }
  }
  //   .submit {
  //     background-color: #0051fb;
  //     border: none;
  //     padding: 8px 25px;
  //     border-radius: 20px;
  //     width: 32%;
  //     font-size: 14px;
  //     color: #ffff;
  //     margin-top: 1rem !important;
  //     margin: auto;
  //     cursor: pointer;
  //     text-decoration: none;
  //     text-align: center;
  //     @media screen and (max-width: 540px) {
  //       width: 40%;
  //     }
  //   }
  .submit {
    text-align: center;
  }
}
@media (max-width: 48rem) {
  #contactForm {
    margin-top: 60px;
    h1 {
      font-size: 32px;
      padding-bottom: 25px;
    }
  }
}
