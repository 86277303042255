
@import "../../../Styles/mixins.scss";
@import "../../../Styles/variables";

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 270px;
  max-width: 270px;
  @include flex(column, "", center);
  padding: 16px 20px 20px 20px;
  gap: 30px;
  z-index: 100;
  background-color: rgb(237, 237, 237);

  @media (max-width: 800px) {
    display: none;
    width: 0px;
    height: 0;
    visibility: hidden;
    padding: 0;
  }

  .sidebar-logo {
    position: relative;
    width: 100%;
    text-align: justify;
    cursor: pointer;
  }

  .sidebar-menu {
    @include flex(column);
    gap: 10px;
    width: 100%;

    .active {
      border-radius: 10px;
      background: $tertiaryColor;
      height: 50px;
      color: white;

      &:hover {
        background: $tertiaryColor;
      }

      .menu-item {
        svg {
          path {
            // stroke: white;
            fill: white;
          }
        }
      }
    }

    a {
      min-width: 90%;
      width: fit-content;
      color: $primaryColor;
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
      @include flex(row, "", center);

      &:hover {
        border-radius: 10px;
        background: rgba(199, 199, 199, 0.412);
      }

      .menu-item {
        padding-inline: 10px;
        @include flex(row, "", center);
        gap: 20px;
        height: 50px;

        .menu-icon {
          @include flex(row, center, center);
          min-width: 30px;
        }

        svg {
          // fill: #fff;
          width: 26px;
          transform: translateY(3px);
          height: 30px;
        }
      }
    }
  }

  .logout-button {
    align-self: flex-start;
    // margin-top: auto;
    @include flex(row, "", center);
    gap: 15px;
    margin-left: 30px;
    color: $errorColor;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
}
