@import "./variables.scss";
@import "./mixins.scss";

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  width: 100%;
  font-family: $Gabarito !important;
  background-color: $light;
  margin: 0;
  // user-select: none;
  @include breakpoint(md) {
    background-size: 100%;
  }
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.app-container {
  max-width: 1440px;
  width: 100%;
  margin-inline: auto;
  .drawer {
    transition: ease-in-out 1s;
    opacity: 0.3;
  }
}

.ant-collapse {
  border-radius: 1px;

  .ant-collapse-header {
    background-color: #fff !important;
    font-size: 20px;
    font-weight: 400;

    .ant-collapse-header-text {
      font-family: "Gabarito", sans-serif !important;
    }
  }
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
  // background-color: rgba(242, 250, 249, 1) !important;
}

@media (max-width: 768px) {
  .ant-collapse .ant-collapse-header {
    font-size: 18px;
  }
}
