.blogs-container {
  padding: 20px;
  margin-top: 120px;

  .loading {
    text-align: center;
    padding: 40px;
    height: 50vh;
  }

  .card-image {
    height: 200px;
    overflow: hidden;
    margin-top: 5px;
    object-fit: contain;
  }

  .card-tags {
    margin-bottom: 12px;
  }

  .card-footer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }
}
