@import "../../../Styles/mixins";
@import "../../../Styles/variables";

.topbar {
  // height: 117px;
  padding-top: 10px;
  padding-bottom: 10px;
  @include flex(row, space-between);
  border-bottom: 1px solid rgb(237, 237, 237);
//   @include customBorder(1px, solid, $secondaryColor, "", bottom);

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

  .topbar-header {
    @include flex($gap: 10px);

    .topbar-back-button {
      margin-top: 15px;
    }

    .page-headings-in-topbar {
      margin-block: 12px;
      font-weight: 700;
    }
  }

  .main-topbar-wrapper {
    @include flex(row, "", center);
    gap: 20px;
    @media (max-width: 800px) {
      flex-direction: column-reverse;
      width: 100%;
    }
    a {
      width: 100%;
    }

    .topbar-button {
      @media (max-width: 800px) {
        margin-inline: auto;
        width: 90%;
      }
      div {
        display: flex;
      }
    }
    .menu-and-picture-actions {
      @media (max-width: 800px) {
        width: 100%;
        @include flex(row, space-between, center);
      }
      .mobile-menu-button {
        display: none;

        @media (max-width: 800px) {
          display: block;
        }
      }
      .picture-actions {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          height: 55px;
          width: 55px;
          object-fit: cover;
          border-radius: 50%;
          display: block;
        }
      }
    }
  }
}

.sidebar-mobile {
  display: flex;
  width: auto;
  height: auto;
  visibility: visible;
  padding: 0px 20px;
  height: 100%;
  background-color: white;
}
