.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    margin-inline: auto;
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
  }
}
