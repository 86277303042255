@import "../../Styles/variables.scss";

#aboutContainer {
  width: 90%;
  // height: 24vh;
  height: 12vh;
  margin: auto;
  margin-top: 70px;

  @media screen and (max-width: 768px) {
    height: 10vh;
    margin-top: 55px;
  }
  .container-heading {
    font-size: 34px;
    color: $primaryColor;
    text-align: center;
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  .about-header {
    // display: flex;
    align-items: center;
    width: 100%;
    // @media screen and (max-width: 768px) {
    //   flex-direction: column;
    //   gap: 20px;
    // }
    .about-header-left {
      // width: 45%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
      // h1 {
      //   width: 80%;
      //   font-size: 33px;
      //   font-weight: 500;
      //   color: $primaryColor;
      //   @media screen and (max-width: 768px) {
      //     font-size: 32px;
      //   }
      // }
    }
    .about-header-right {
      width: 70%;
      margin-inline: auto;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      p {
        color: $tertiaryColor;
        font-weight: 300;
        text-align: center;
        @media screen and (max-width: 768px) {
          text-align: justify;
        }
      }
    }
  }
}

// slides

.slides {
  max-width: 90%;
  margin: auto;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    max-width: 86%;
  }
}
.img {
  width: 100px;
  img {
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0px 20px 0px !important;
    }
  }
}

/* Commitment and Healthier lives */

.container {
  width: 90%;
  margin-inline: auto;
  @media screen and (max-width: 991px) {
    margin-top: 0px;
    margin-bottom: 40px;
    height: auto;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 130px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .commitment_and_healthier_lives {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }
    .commitment_and_healthier_lives_left {
      width: 100%;
      max-width: 400px;
      height: auto;
      background-color: #fff;
      // border: 1px solid rgb(208, 221, 225);
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: left;
      transition: transform 0.4s ease, box-shadow 0.4s ease;
      @media screen and (max-width: 768px) {
        max-width: 340px;
      }
      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2),
          0 8px 10px rgba(0, 0, 0, 0.15);
      }
      h2 {
        font-size: 23px;
        padding: 15px 15px 0;
        font-weight: 500;
        color: $primaryColor;
      }
      p {
        color: $tertiaryColor;
        padding: 12px 15px 18px;
        text-align: justify;
      }
    }
    .commitment_and_healthier_lives_right {
      width: 100%;
      max-width: 400px;
      height: auto;
      background-color: #fff;
      // border: 1px solid rgb(208, 221, 225);
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      text-align: left;
      transition: transform 0.4s ease, box-shadow 0.4s ease;
      @media screen and (max-width: 768px) {
        max-width: 340px;
      }
      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2),
          0 8px 10px rgba(0, 0, 0, 0.15);
      }

      h2 {
        padding: 15px 15px 0;
        font-size: 22px;
        font-weight: 500;
        color: $primaryColor;
      }
      p {
        color: $tertiaryColor;
        padding: 12px 15px 18px;
        text-align: justify;
      }
    }
  }
}
